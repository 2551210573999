<template>
<div class="mainform">
    <!-- 索赔单详情 -->
    <div class="mainHeader">索赔单 {{form.qualityCode}} <span @click="back" class="back">返回></span></div>
    <div class="form mg-form fbxj-table">
      <div class="xunjia-news"><span></span>基本信息</div>
      <el-form class="bascform" ref="form" :model="form" label-width="130px">
        <div class="content">
          <div class="col col4">
            <el-form-item label="索赔单号">
              <span class="onlyText">
                {{form.qualityCode}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔类型">
              <span class="onlyText">
                {{form.claimType==1?'来料检验索赔':form.claimType==2?'售后索赔':form.claimType==3?'生产过程索赔':'行政索赔'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商">
              <span class="onlyText">
                {{form.supplierName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔来源">
              <span class="onlyText">
                {{form.claimSource==1?'来料':form.claimSource==2?'售后':form.claimSource==3?'生产线':'研发'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
              <span class="onlyText">
                {{form.companyName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="要求反馈日期">
              <span class="onlyText">
                {{form.feedbackDate}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="采购员">
              <span class="onlyText">
                {{form.buyerName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="币种">
              <span class="onlyText">
                {{form.currency==1?'RMB':form.currency==2?'USD':form.currency}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="索赔说明">
              <span class="onlyText">
                {{form.claimDesc}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="状态">
              <span class="onlyText">
                {{form.status==null?'待供应商确认':form.status==1?'待供应商确认':form.status==2?'待我确认':form.status==3?'待确认赔付':form.status==4? '已完成':''}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4 notice-order">
            <el-form-item label="质量责任通知单">
              <el-upload
                disabled
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :file-list="form.fileInfoList"
              >
              </el-upload>
            </el-form-item>
          </div>
          <div class="col col4" style="vertical-align: top;">
            <el-form-item label="索赔总额">
              <span class="onlyText">
                {{form.claimMoney}}
              </span>
            </el-form-item>
          </div>
        </div>

      </el-form>
      <!-- 索赔申诉 在 待我确认状态下显示-->
      <div class="xunjia-news" v-if="form.status ==2"><span></span>索赔申诉</div>
      <div class="content bascform" v-if="form.status ==2">
        <div class="ssyj">
          <div class="yjcont">
            <span>2021-05-11 11:20:11</span>
            <span>曙光公司</span>
            <span>销售部</span>
            <span>潘阳</span>
          </div>
          <div class="ssyjsm">申诉意见说明</div>
        </div>
      </div>
      <!-- 申诉意见和 索赔凭证 在 状态为 3 待确认赔付的时候 显示 -->
      <!-- 2023-08-17 经与贾海飞确认 任何情况下都不展示申诉意见 -->
      <!-- <div class="xunjia-news" v-if="form.status ==3"><span></span>申诉意见</div>
      <div class="content bascform" v-if="form.status ==3">
        <div class="ssyj">
          <div class="yjcont">
            <span>2021-05-11 11:20:11</span>
            <span>曙光公司</span>
            <span>销售部</span>
            <span>潘阳</span>
          </div>
          <div class="ssyjsm">申诉意见说明</div>
          <div class="yjqm">
            <span>2021-05-13 15:00:45</span>
            <span>员工内部名</span>
          </div>
        </div>
      </div> -->
      <div class="xunjia-news" v-if="form.status == 3 || form.status == 4">
        <span></span>索赔凭证
      </div>
      <div class="fkpz content" v-if="form.status == 3 || form.status == 4">
        <el-form>
          <el-form-item label="付款凭证">
            <el-upload
              ref="upload2"
              name="file"
              :disabled="form.status !== 3"
              :auto-upload="true"
              :action= 'actionURL'
              :on-preview="downBylob"
              :on-remove="handleRemoveQsd"
              :on-success="handSuccessPay"
              :headers="tokenHeader"
              :file-list="upReturnData"
            >
              <span v-if="form.status == 3">付款凭证：</span><el-button size="mini" type="primary" plain v-if="form.status == 3">上传附件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div class="action" v-if="form.status ==3">
        <el-button class="submit" type="primary" size="medium" @click="preservation">提交</el-button>
      </div>
      <!-- 待我确认状态 显示 驳回改判按钮  -->
      <div class="action" v-if="form.status ==2">
        <el-button class="submit" type="primary" size="medium" @click="reject">驳回</el-button>
        <el-button class="submit" type="primary" size="medium" @click="Commute">改判</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, baseURL } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import { fileHandle } from '@/assets/js/FileAction.js'
export default {
  components: Component.components,
  name: 'AddClaimForm',
  props: [],
  data: function () {
    return {
      form: { file: [] },
      upReturnData: [],
      id: '',
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      tokenHeader: {
        token: localStorage.getItem('token')
      }
    }
  },

  mounted () {
    this.id = this.$route.query.id
    request('/api/quality/claim/getById/' + this.id, 'get').then((res) => {
      if (res.code === '200') {
        this.form = res.data
        this.upReturnData = res.data?.payList || []
      }
    })
  },
  methods: {
    // 保存
    preservation () {
      const obj = {
        // id: this.id,
        ...this.form,
        status: 4 // 完成
      }
      console.log('obj...', obj)
      request('/api/quality/claim/save', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/QualitySynergy/Responsibility')
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
      /* exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name
            .substring(file.name.lastIndexOf('.') + 1)
            .toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        }
      ) */
    },
    handleRemove (file) {
      for (var i = 0; i < this.BasicForm.fileInfoList.length; i++) {
        if (this.BasicForm.fileInfoList[i].id === file.id) {
          this.BasicForm.fileInfoList.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileInfoList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileInfoList) {
      if (response.code === '200') {
        response.data.forEach((element) => {
          this.BasicForm.fileInfoList.push(element)
        })
      }
    },
    downBylob (file) {
      fileHandle.handlePreview(file)
    },
    handSuccessPay (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(item => {
          this.upReturnData.push(item)
        })
      }
    },
    handleRemoveQsd (file, list) {
      console.log(file)
      this.upReturnData = list
      request('/api/file/m/file/delFile/' + file.id, 'get')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .fbxj-table{
    .xunjia-news{
      color: #666;
      font-size: 16px;
      line-height: 50px;
      border-bottom: 1px solid #eee;
      span{
        display: inline-block;
        width: 3px;
        background: #ccc;
        vertical-align: -4px;
        height: 20px;
        background: #4487fa;
        margin-right: 5px;
      }
    }
    .action{
      margin: 10px 0 30px;;
    }
    .ssyj{
      padding: 20px;
      border: 1px dashed #ccc;
      color: #666;
      font-size: 14px;
    }
    .ssyjsm{
      margin: 10px 0;
    }
    .yjcont span{
      padding-right: 15px;
    }
    .yjqm{
      text-align: right;
    }
    .yjqm span{
      padding-left: 15px;
    }
    .fkpz{
      padding: 20px 0 0;
    }
  }
  :deep(.el-form){
    .notice-order {
      .el-upload.el-upload--text{
        display: none;
      }
    }
  }
</style>
